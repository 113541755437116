<template>
  <div>
    <div v-if="relations.length > 0">
      <div :class="cardClasses(person)" v-for="(person, index) in relations" :key="index">
        <div class="card--profile__img">
          <div :style="profileImgStyle(person.additionalInfos.images)"></div>
        </div>
        <div class="card--profile__user">
          <span>{{componseName(person.additionalInfos.person)}}</span>
          <span>{{person.labelRelationType}}</span>
        </div>
        <div
          class="card--profile__extras"
          v-if="person.expireDate"
        >
          <div>
            <span>Scadenza utenza</span>
            <span>{{ cardInviteExpiration(person.expireDate) }}</span>
          </div>
        </div>
        <div
          class="card--profile__action"
          v-if="person.relationContext === 'company_connection'"
        >
          <b-button
            variant="danger"
            @click="removeInvitation(person)"
            size="sm"
          >
            <font-awesome-icon icon="remove" class="fa-fw" />
            Revoca
          </b-button>
        </div>
      </div>
    </div>
    <div v-else class="mt-3 mb-4 text-center">
      <p class="mb-0">
        <em>Nessun utente collegato</em>
      </p>
    </div>
    <b-card class="mt-3 border-0" body-class="p-2 bg-gray-200 text-right">
      <b-button
        @click="invitaAction"
        size="sm"
      >
        Invita utenti
      </b-button>
    </b-card>
    <user-invite-modal :id="wizardModalId" :on-close-modal="fetchData" />
  </div>
</template>

<script>
import { createPublicUri } from '@/utils/utilities';
import { isNotEmpty } from '@/utils/validators';
import { formatDateOnly } from '../../utils/formatterHelper';

const UserInviteModal = () => import('@/components/userSections/UserInviteModal.vue');

export default {
  name: 'CompanyRelations',
  components: { UserInviteModal },
  props: {},
  data() {
    return {
      // eslint-disable-next-line global-require
      userPlaceholder: require('@/assets/img/user-img_placeholder.png'),
      wizardModalId: 'userInviteModal',
    };
  },
  computed: {
    relations() {
      return this.$store.getters['subject/currentCompanyRelations'] || [];
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      const company = this.$store.getters['subject/currentCompany'];
      if (isNotEmpty(company)) {
        this.$store.dispatch('subject/loadCompanyRelations', company.companyId);
      }
    },
    profileImgStyle(img) {
      const imgUrl = img.length
        ? createPublicUri(img[0].documentUpload.externalId)
        : this.userPlaceholder;
      return `background-image: url(${imgUrl});`;
    },
    componseName(person) {
      if (isNotEmpty(person)) {
        return `${person.name} ${person.surname}`;
      }
      return '';
    },
    invitaAction() {
      this.$bvModal.show(this.wizardModalId);
    },
    removeInvitation(p) {
      console.log('removing p', p);
    },
    cardClasses(item) {
      const baseClasses = 'card card--profile';
      if (item.status === 'TO_VALIDATE') return `${baseClasses} border-warning`;
      console.log('item', item);
      return baseClasses;
    },
    cardInviteExpiration(date) {
      return formatDateOnly(date);
    },
  },
};
</script>

<style scoped>

</style>
